import TYPES from '../actions/types';

const initialState = {
  name: 'Anonymous',
  email: 'example@example.com',
  role: 'student',
  id: '',
  inClass: false,
};

export default (state = initialState, { payload, type }) => {
  const newState = { ...state };

  switch (type) {
    /**
     * Resets user object.
     */
    case TYPES.CLEAR_USER:
      return {
        name: 'Anonymous',
        email: 'example@example.com',
        role: 'student',
        id: '',
        inClass: false,
      };

    /**
     * Sets up user object
     */
    case TYPES.SET_USER:
      Object.keys(payload).forEach(key => {
        if (typeof newState[key] !== 'undefined') {
          newState[key] = payload[key];
        }
      });
      return newState;

    default:
      return state;
  }
};
