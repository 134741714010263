/**
 * Stores all action types here so it's harder to do typos and confuse yourself.
 */

export default {
  SET_STUDENT_SESSION: 'SET_STUDENT_SESSION',
  CLEAR_STUDENT_SESSION: 'CLEAR_STUDENT_SESSION',
  SET_INSTRUCTOR_SESSION: 'SET_INSTRUCTOR_SESSION',
  CLEAR_INSTRUCTOR_SESSION: 'CLEAR_INSTRUCTOR_SESSION',
  CLEAR_USER: 'CLEAR_USER',
  SET_USER: 'SET_USER',
  SET_ACTIVE_VIEW: 'SET_ACTIVE_VIEW',
  SWAP_TO: 'SWAP_TO',
  SET_INSTRUCTOR_VIEW: 'SET_INSTRUCTOR_VIEW',
  INSTRUCTOR_ROLE: 'instructors',
  STUDENT_ROLE: 'students',
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  DISABLE_SWAP: 'DISABLE_SWAP',
  ENABLE_SWAP: 'ENABLE_SWAP',
};
