/*eslint-disable */

import React from 'react';
import ApolloClient from 'apollo-boost';
import { Auth } from 'aws-amplify';
import { ApolloProvider } from '@apollo/react-hooks';

import Root from './src/components/Root';
import Layout from './src/components/Layout';
import './node_modules/@aws-amplify/ui/dist/style.css';
import 'react-toastify/dist/ReactToastify.css';
import './src/styles/reset.css';
import './src/styles/global.css';
import './src/styles/fonts.css';

/**
 * Promise polyfill. Will only overtake the promise global object if it doesn't exist.
 */
import 'promise-polyfill/src/polyfill';

/**
 * apollo for client-side.
 * uri - The graphql server URL
 * request - Attaches token for every new request we do in the app.
 * defaultOptions - Attach fetchPolicies to the apollo client.
 * https://www.apollographql.com/docs/react/api/apollo-client/#the-apolloclient-constructor
 */
const apolloClient = new ApolloClient({
  // https://www.babelhippo.com
  // https://0h1bhffxg8.execute-api.ap-southeast-1.amazonaws.com/dev/graphql
  // http://localhost:4000
  uri:
    'https://0h1bhffxg8.execute-api.ap-southeast-1.amazonaws.com/dev/graphql',
  request: async operation => {
    const token = await Auth.currentSession();
    const accessToken = token.accessToken.jwtToken;

    operation.setContext({
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    });
  },
});

apolloClient.defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const wrapRootElement = Root;

const wrapPageElement = ({ element: page }) => {
  return (
    <ApolloProvider client={apolloClient}>
      <Layout>{page}</Layout>
    </ApolloProvider>
  );
};

export default {};

export { wrapRootElement, wrapPageElement };
