import TYPES from '../actions/types';

const initialState = {
  instructorVM: '',
  twilioRoomName: '',
  students: [],
  activeView: '',
  swapTo: '',
  wsInstance: undefined,
  swapState: false,
};

export default (state = initialState, { payload, type }) => {
  const newState = { ...state };

  switch (type) {
    /**
     * Sets up instructor session. Store vms, vids here. Also sets the initial
     * activeView and swapTo value.
     */
    case TYPES.SET_INSTRUCTOR_SESSION:
      newState.instructorVM = payload.instructorVM;
      newState.twilioRoomName = payload.twilioRoomName;
      newState.students = payload.students;

      newState.activeView = payload.instructorVM;

      newState.wsInstance = payload.wsInstance;

      newState.swapTo = payload.instructorVM;
      return newState;

    /**
     * Clears instructor session after a class ends.
     */
    case TYPES.CLEAR_INSTRUCTOR_SESSION:
      return {
        instructorVM: '',
        twilioRoomName: '',
        students: [],
        activeView: '',
        swapTo: '',
        wsInstance: undefined,
        swapState: false,
      };

    /**
     * Sets instructor active view to VM.
     */
    case TYPES.SET_ACTIVE_VIEW:
      newState.activeView = payload.value;
      return newState;

    /**
     * Updating this value informs websocket to ping all students to swap to
     * whatever VM the instructor is viewing.
     */
    case TYPES.SWAP_TO:
      newState.swapTo = newState.activeView.value;

      return newState;

    /**
     * Enable swapping of student's view of instructor's VM.
     */
    case TYPES.ENABLE_SWAP:
      newState.swapState = true;
      return newState;

    /**
     * Disable swapping of student's view of instructor's VM.
     */
    case TYPES.DISABLE_SWAP:
      newState.swapState = false;
      return newState;

    default:
      return state;
  }
};
