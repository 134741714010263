import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../../styles/global';

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + span {
    background-color: ${({ falseColor }) => {
      return falseColor;
    }};
  }

  &:checked + span:before {
    transform: translateX(100%);
  }

  &:focus + span {
    box-shadow: 0 0 2px ${$.color.gray1};
  }
`;

const Tab = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ trueColor }) => {
    return trueColor;
  }};
  transition: 0.4s;
  border-radius: 999px;

  &:before {
    position: absolute;
    content: '';
    border-radius: 999px;
    height: calc(100% - 8px);
    width: calc(50% - 4px);
    left: 4px;
    bottom: 4px;
    background-color: ${$.color.white};
    transition: 0.4s;
  }
`;

const Toggle = ({
  onCheck,
  defaultValue,
  trueColor,
  falseColor,
  tabColor,
  dataTip,
  className,
}) => {
  return (
    <Switch className={className} data-tip={dataTip}>
      <Checkbox
        falseColor={falseColor}
        onChange={e => {
          onCheck(e.target.checked);
        }}
        defaultChecked={defaultValue}
      />
      <Tab trueColor={trueColor} tabColor={tabColor} />
    </Switch>
  );
};

Toggle.defaultProps = {
  defaultValue: false,
  className: '',
  dataTip: '',
  trueColor: '#2196f3',
  tabColor: $.color.white,
  falseColor: $.color.gray1,
  onCheck: () => {},
};

Toggle.propTypes = {
  defaultValue: PropTypes.bool,
  className: PropTypes.string,
  tabColor: PropTypes.string,
  trueColor: PropTypes.string,
  dataTip: PropTypes.string,
  falseColor: PropTypes.string,
  onCheck: PropTypes.func,
};

export default Toggle;
