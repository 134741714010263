import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import { navigate } from 'gatsby';
import ReactTooltip from 'react-tooltip';

import useActions from '../../utils/useActions';
import { openModal, clearInstructorSession } from '../../actions';
import $ from '../../styles/global';
import TYPES from '../../actions/types';
import { endClassGQL } from '../../apollo/instructor';
import Button from '../Button';

const StyledButton = styled(Button)`
  margin-right: ${$.layout().margin2}px;
`;

/**
 * Only shown to instructor. Ends an ongoing class.
 */
const EndClassButton = () => {
  const state = useSelector(({ user, instructorSession }) => {
    return { user, instructorSession };
  });
  const [endClass] = useMutation(endClassGQL);
  const [showConfirmation, clearCurrentSession] = useActions([
    openModal,
    clearInstructorSession,
  ]);
  const atClassPage =
    typeof window !== 'undefined' &&
    window.location.pathname.indexOf('/instructor/class') > -1;
  const showButton =
    state.user.role === TYPES.INSTRUCTOR_ROLE &&
    state.user.inClass &&
    atClassPage &&
    state.instructorSession.instructorVM !== '';

  return showButton ? (
    <>
      <ReactTooltip delayShow={500} />
      <StyledButton
        data-tip="Ends the ongoing class"
        onClick={() => {
          showConfirmation({
            title: 'End class confirmation',
            components: (
              <div>
                Ending the class now will shut down all instances, and you will
                be redirected back to the dashboard. Do you want to end the
                class?
              </div>
            ),
            buttons: [
              {
                text: 'Cancel',
                color: $.color.gray2,
              },
              {
                text: 'Yes',
                color: $.color.green,
                callback: () => {
                  const ws = state.instructorSession.wsInstance;

                  /**
                   * 1. Informs all students to end class.
                   * 2. Ping server to clear DB and shutdown instances.
                   * 3. Disconnect from EB ws server.
                   * 4. Redirect to /instructor page and clear instructor session. Waits 2s before navigating
                   * away because we need to wait noVNC and websocket to clear their stuff up.
                   */
                  ws.endClass();
                  endClass();
                  ws.exit();

                  setTimeout(() => {
                    navigate('/instructor/');
                    clearCurrentSession();
                  }, 2000);
                },
              },
            ],
          });
        }}
      >
        End Class
      </StyledButton>
    </>
  ) : null;
};

export default EndClassButton;
