import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { enableSwap, disableSwap } from '../../../actions';
import TYPES from '../../../actions/types';
import useActions from '../../../utils/useActions';
import $ from '../../../styles/global';
import Toggle from './Toggle';

const Container = styled.div`
  margin-right: ${$.layout().margin4}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  > * {
    margin-right: ${$.layout().margin1}px;
    &:last-child {
      margin-right: 0;
    }
  }

  > label {
    width: 30px;
    height: 18px;
  }

  > span {
    text-transform: uppercase;
    color: ${$.color.blue4};
    font-size: 14px;
    font-family: Ruda Regular;
  }
`;

/**
 * Only shown to instructors.
 * Allow instructor to swap student's view between VM and Video.
 */
const SwapToggle = () => {
  const state = useSelector(({ user, instructorSession }) => {
    return { user, instructorSession };
  });
  const [allowSwap, disallowSwap] = useActions([enableSwap, disableSwap]);
  const atClassPage =
    typeof window !== 'undefined' &&
    window.location.pathname.indexOf('/instructor/class') > -1;
  const showToggle =
    state.user.role === TYPES.INSTRUCTOR_ROLE &&
    state.user.inClass &&
    atClassPage &&
    state.instructorSession.instructorVM !== '';

  return showToggle ? (
    <Container>
      <ReactTooltip delayShow={500} />
      <span data-tip="Toggle to change swap settings">Prevent swapping</span>
      <Toggle
        dataTip={
          state.instructorSession.swapState
            ? 'Swapping allowed'
            : 'Swapping blocked'
        }
        onCheck={isChecked => {
          if (isChecked === true) {
            allowSwap();
          } else {
            disallowSwap();
          }
        }}
        defaultValue={state.instructorSession.swapState}
        trueColor={$.color.green}
        falseColor={$.color.red}
      />
    </Container>
  ) : (
    ''
  );
};

export default SwapToggle;
