import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import DownArrow from '../assets/icons/drop-down.svg';
import RightArrow from '../assets/icons/drop-right.svg';
import $ from '../styles/global';

const StyledDownArrow = styled(DownArrow)`
  ${({ counter }) => `
    transform: rotate(${counter * 180}deg);
    transition: transform 0.5s ${$.easingFn.standard};
    height: 8px;
  `}
`;

const StyledRightArrow = styled(RightArrow)`
  ${({ counter }) => `
    transform: rotate(${counter * 180}deg);
    transition: transform 0.5s ${$.easingFn.standard};
    height: 8px;
  `}
`;

const RotatingArrow = ({ counter, className, sideways }) =>
  sideways ? (
    <StyledRightArrow className={className} counter={counter} />
  ) : (
    <StyledDownArrow className={className} counter={counter} />
  );

RotatingArrow.defaultProps = {
  counter: 0,
  className: '',
  sideways: false,
};

RotatingArrow.propTypes = {
  counter: PropTypes.number,
  sideways: PropTypes.bool,
  className: PropTypes.string,
};

export default RotatingArrow;
