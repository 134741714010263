import { gql } from 'apollo-boost';

const createStudentGQL = gql`
  mutation createStudentRegistry(
    $username: String!
    $password: String!
    $email: String!
    $name: String!
  ) {
    createStudentRegistry(
      username: $username
      password: $password
      email: $email
      name: $name
    ) {
      studentId
      username
      email
      name
      userCreateDate
      userLastModifiedDate
    }
  }
`;

export default createStudentGQL;
