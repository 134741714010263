import { combineReducers } from 'redux';

import userReducer from './userReducer';
import instructorSessionReducer from './instructorSessionReducer';
import studentSessionReducer from './studentSessionReducer';
import modalReducer from './modalReducer';

export default combineReducers({
  modal: modalReducer,
  user: userReducer,
  studentSession: studentSessionReducer,
  instructorSession: instructorSessionReducer,
});
