import React, { useRef } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import shortid from 'shortid';

import $ from '../styles/global';
import ClearIcon from '../assets/icons/clear.svg';
import Button from './Button';
import Utils from '../utils';
import useActions from '../utils/useActions';
import { closeModal } from '../actions';

const Background = styled.div`
  ${({ show }) => `
    display: ${show ? 'block' : 'none'};
    pointer-events: ${show ? 'initial' : 'none'};
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background-color: rgba(68, 74, 70, 0.8);
  `}
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background-color: transparent;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Dialog = styled.div`
  width: 500px;
  max-height: 40vw;
  overflow: auto;
  border-radius: ${$.border().radius1}px;
  background-color: ${$.color.white};
  box-shadow: ${$.dropShadow.normal};

  > * {
    padding: 0 ${$.layout().padding2}px;
  }
`;

const DialogTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: ${$.layout().padding2}px;
  margin-bottom: ${$.layout().margin2}px;

  > div {
    font-size: 16px;
    font-family: Ruda Bold;
    color: ${$.color.blue5};
  }

  > svg {
    width: 24px;
    height: 24px;
    fill: ${$.color.gray2};
    &:hover {
      cursor: pointer;
      fill: ${$.color.gray1};
    }
  }
`;

const DialogBody = styled.div`
  margin-bottom: ${$.layout().margin2}px;
`;

const DialogButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: ${$.layout().padding2}px;

  > * {
    margin-right: ${$.layout().margin2}px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledButton = styled(Button)`
  ${({ color }) => `
    background-color: ${color};
    &:hover {
      color: ${color};
      border: 2px solid ${color};
    }
  `}
`;

/**
 * Modal dialog that shows up/hides whenever you call closeModal and openModal redux actions.
 */
const Modal = () => {
  const [close] = useActions([closeModal]);
  const state = useSelector(({ modal }) => {
    return { modal };
  });
  const containerRef = useRef(null);

  /** Automatically close the dialog box if clicked anywhere else but the dialog box. */
  Utils.useOutsideClick([containerRef], () => {
    close();
  });

  return (
    <Background show={state.modal.show}>
      <Container>
        <Dialog ref={containerRef}>
          <DialogTitle>
            <div>{state.modal.title}</div>
            <ClearIcon
              onClick={() => {
                close();
              }}
            />
          </DialogTitle>
          <DialogBody>{state.modal.components}</DialogBody>
          <DialogButtons>
            {state.modal.buttons.map(({ text, callback, color }) => {
              /**
               * Creates a button for every object found in the modal.buttons array.
               */
              return (
                <StyledButton
                  key={shortid.generate()}
                  color={color}
                  onClick={() => {
                    if (callback && typeof callback === 'function') {
                      callback();
                    }
                    close();
                  }}
                >
                  {text}
                </StyledButton>
              );
            })}
          </DialogButtons>
        </Dialog>
      </Container>
    </Background>
  );
};

export default Modal;
