import { gql } from 'apollo-boost';

const getClassGQL = gql`
  query instructor($instructorId: String!) {
    instructor(instructorId: $instructorId) {
      instructorId
      ipAddress
      twilioRoomName
      enlistedStudents {
        studentId
        ipAddress
        name
        twilioRoomName
      }
    }
  }
`;

export default getClassGQL;
