import { gql } from 'apollo-boost';

const restartInstanceGQL = gql`
  query restartInstance($userId: String!) {
    restartInstance(userId: $userId) {
      status
      message
    }
  }
`;

export default restartInstanceGQL;
