import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import Amplify, { Auth } from 'aws-amplify';
import { ToastContainer } from 'react-toastify';
import {
  Authenticator,
  SignIn,
  ConfirmSignIn,
  ForgotPassword,
  RequireNewPassword,
} from 'aws-amplify-react';

import Modal from './Modal';
import TYPES from '../actions/types';
import Nav from './Nav';
import Logo from '../assets/images/sgcc-logo.png';
import awsconfig from '../../aws-config';
import $ from '../styles/global';
import Utils from '../utils';
import {
  setUser,
  clearUser,
  clearInstructorSession,
  clearStudentSession,
} from '../actions';
import useActions from '../utils/useActions';

Amplify.configure(awsconfig);

const Body = styled(({ className, children }) => {
  return <div className={className}>{children}</div>;
})`
  ${({ show }) => `
    width: 100%;
    height: 100vh;
    display: ${show ? 'flex' : 'none'};
    flex-direction: column;

    > *:last-child {
      flex-grow: 2;
    }

    .react-contextmenu {
      background-color: ${$.color.white};
      padding: ${$.layout().padding1}px;
      border-radius: ${$.border().radius1}px;
      border: 1px solid ${$.color.gray2};
      font-size: 15px;
      font-family: Ruda Bold;
      z-index: 1;
      min-width: 140px;
      user-select: none;
      transition: all 0.3s ${$.easingFn.standard};

      &:hover {
        background-color: ${$.color.gray2};
        cursor: pointer;
      }
    }

    .Toastify {
      > div {
        > div {
          box-shadow: none;
          border-radius: ${$.border().radius1}px;
          padding: ${$.layout().padding2}px;
          font-family: Dosis Regular;
          font-size: 16px;
          color: ${$.color.lightblack};
          > button {
            opacity: 1;
          }

          &.toast-error {
            background-color: ${$.color.red};
            color: ${$.color.white};
            > button > svg {
              fill: ${$.color.white};
            }
          }

          &.toast-success {
            background-color: ${$.color.green};
            color: ${$.color.white};
            > button > svg {
              fill: ${$.color.white};
            }
          }

          &.toast-info {
            background-color: ${$.color.white};
            color: ${$.color.blue4};
            > button > svg {
              fill: ${$.color.blue4};
            }
          }
        }
      }
    }
  `}
`;

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const SignInTitleContainer = styled(({ className, children }) => {
  return <div className={className}>{children}</div>;
})`
  ${({ show }) => `
    display: ${show ? 'flex' : 'none'};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 30px;

    > img {
      width: 80px;
      height: 80px;
    }
  `}
`;

const Title = styled.h1`
  font-family: Ruda Regular;
  font-size: 40px !important;
  letter-spacing: -1px !important;
  color: ${$.color.blue5};
`;

const NoRoleMessage = styled(({ className, children }) => {
  return <div className={className}>{children}</div>;
})`
  ${({ show }) => `
    display: ${show ? 'flex' : 'none'};
    color: ${$.color.red};
    font-size: 17px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}
`;

/**
 * Refer to https://github.com/aws-amplify/amplify-js/blob/master/packages/aws-amplify-react/src/Amplify-UI/Amplify-UI-Theme.tsx and cross reference with className using Inspect.
 */
const amplifyTheme = {
  formSection: {
    borderRadius: '20px',
  },
  sectionHeader: {
    fontSize: '19px',
    fontFamily: 'Dosis Regular',
    color: $.color.blue4,
  },
  input: {
    outline: 'none',
    borderRadius: '5px',
    padding: '10px',
    fontFamily: 'Dosis Regular',
    fontSize: '16px',
    color: $.color.blue4,
    letterSpacing: '1px',
    lineHeight: '16px',
    border: `1px solid ${$.color.gray1}`,
  },
  inputLabel: {
    fontFamily: 'Ruda Regular',
    fontSize: '15px',
    letterSpacing: '1px',
    color: $.color.blue4,
    textTransform: 'uppercase',
  },
  hint: {
    fontSize: '16px',
    color: $.color.blue4,
  },
  a: {
    fontFamily: 'Dosis Bold',
    color: $.color.orange4,
    textDecoration: 'none',
  },
  sectionFooter: {
    fontSize: '16px',
  },
  button: {
    fontSize: '16px',
    fontFamily: 'Ruda Bold',
    borderRadius: '10px',
    backgroundColor: $.color.orange3,
    outline: 'none',
  },
  toast: {
    backgroundColor: $.color.blue4,
    color: $.color.blue1,
    fontFamily: 'Dosis Regular',
    fontSize: '17px',
  },
};

/**
 * To add a logo on top of the form, we need to handle the show/hiding of our app's component ourselves.
 * Refer to onStateChange to see how I determine when to hide or show the app.
 */
const Layout = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [
    setCurrentUser,
    clearCurrentUser,
    clearCurrentInstructorSession,
    clearCurrentStudentSession,
  ] = useActions([
    setUser,
    clearUser,
    clearInstructorSession,
    clearStudentSession,
  ]);
  const [noRole, setNoRole] = useState(false);
  let userPayload;

  return (
    <Authenticator
      hideDefault
      theme={amplifyTheme}
      onStateChange={state => {
        let role;
        /**
         * 1. signedIn = logged in successfully
         * 2. signIn = logged out (Must see index page once logged out)
         */
        switch (state) {
          case 'signedIn':
            userPayload = Auth.user.signInUserSession.accessToken.payload;

            role = userPayload['cognito:groups']
              ? userPayload['cognito:groups'][0]
              : '';

            switch (role) {
              case TYPES.INSTRUCTOR_ROLE:
              case TYPES.STUDENT_ROLE:
                setCurrentUser({
                  name: userPayload.username,
                  email: userPayload.email,
                  id: userPayload.sub,
                  role,
                });

                setIsLoggedIn(true);
                Utils.redirect(role);
                break;
              /**
               * No role assigned to this account. Logout immediately and show error message.
               */
              default:
                Auth.signOut().catch(err => console.log(err));
                setNoRole(true);
                break;
            }
            break;
          case 'signIn':
            /**
             * User has signed out. Clear current user's state, student or instructor session.
             * Show login form.
             */
            navigate('/');
            setIsLoggedIn(false);
            /**
             * We set a timeout of 0 to ensure navigate('/') happened first and components
             * are allowed to unmount first before we start to clear the state.
             */
            setTimeout(() => {
              clearCurrentUser();
              clearCurrentInstructorSession();
              clearCurrentStudentSession();
            }, 0);
            break;
          default:
            setIsLoggedIn(false);
            break;
        }
      }}
    >
      <SignInTitleContainer show={!isLoggedIn}>
        <img src={Logo} alt="" />
        <Title>SGCC Online Platform App</Title>
      </SignInTitleContainer>
      <SignIn />
      <ConfirmSignIn />
      <ForgotPassword />
      <RequireNewPassword />
      <NoRoleMessage show={!isLoggedIn && noRole}>
        Failed to sign in. No role assigned to this account. Please contact the
        tech team.
      </NoRoleMessage>
      <Body show={isLoggedIn}>
        {isLoggedIn ? (
          <>
            <Nav />
            <Modal />
            <ToastContainer autoClose={6000} hideProgressBar closeOnClick />
            <MainContainer>{children}</MainContainer>
          </>
        ) : (
          ''
        )}
      </Body>
    </Authenticator>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
