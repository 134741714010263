import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import $ from '../../styles/global';
import TYPES from '../../actions/types';
import LogoImg from '../../assets/icons/sgcc.svg';
import Dropdown from './Dropdown';
import EndClassButton from './EndClassButton';
import SwapToggle from './SwapToggle';

const Container = styled.div`
  border-bottom: 1px solid ${$.color.gray1};
  padding: ${$.layout().padding2}px 0;
`;

const InnerContainer = styled.div`
  width: 80%;
  height: 40px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > * {
    height: 100%;
  }
`;

const LogoContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
`;

const Logo = styled(Link)`
  text-decoration: none;
  display: inline-block;
  height: 100%;
  width: 180px;

  > svg {
    width: 100%;
    height: 100%;
  }
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Nav = () => {
  const state = useSelector(({ user }) => {
    return { user };
  });

  return (
    <Container>
      <InnerContainer>
        <LogoContainer>
          <ReactTooltip delayShow={500} />
          <Logo
            data-tip="Back to homepage"
            to={
              state.user.role === TYPES.INSTRUCTOR_ROLE ? '/instructor/' : '/'
            }
          >
            <LogoImg />
          </Logo>
        </LogoContainer>
        <DropdownContainer>
          <SwapToggle />
          <EndClassButton />
          <Dropdown />
        </DropdownContainer>
      </InnerContainer>
    </Container>
  );
};

export default Nav;
