import { gql } from 'apollo-boost';

const getAllStudentsGQL = gql`
  query getAllStudentsRegistry {
    getStudentsRegistry {
      username
      studentId
      email
      name
      userCreateDate
      userLastModifiedDate
    }

    students {
      studentId
      instructor {
        name
      }
    }
  }
`;

export default getAllStudentsGQL;
