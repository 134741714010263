// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-instructor-class-jsx": () => import("./../src/pages/instructor/class.jsx" /* webpackChunkName: "component---src-pages-instructor-class-jsx" */),
  "component---src-pages-instructor-create-jsx": () => import("./../src/pages/instructor/create.jsx" /* webpackChunkName: "component---src-pages-instructor-create-jsx" */),
  "component---src-pages-instructor-index-jsx": () => import("./../src/pages/instructor/index.jsx" /* webpackChunkName: "component---src-pages-instructor-index-jsx" */),
  "component---src-pages-instructor-new-jsx": () => import("./../src/pages/instructor/new.jsx" /* webpackChunkName: "component---src-pages-instructor-new-jsx" */),
  "component---src-pages-instructor-student-vms-jsx": () => import("./../src/pages/instructor/student-vms.jsx" /* webpackChunkName: "component---src-pages-instructor-student-vms-jsx" */),
  "component---src-pages-instructor-vm-jsx": () => import("./../src/pages/instructor/vm.jsx" /* webpackChunkName: "component---src-pages-instructor-vm-jsx" */)
}

