import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import { navigate } from 'gatsby';
import ReactTooltip from 'react-tooltip';

import $ from '../../styles/global';
import PersonIcon from '../../assets/icons/account.svg';
import Utils from '../../utils';
import RotatingArrow from '../RotatingArrow';
import TYPES from '../../actions/types';

const Container = styled.div`
  position: relative;
  background-color: ${$.color.white};
`;

const StyledRotatingDropArrow = styled(RotatingArrow)`
  width: 25px;
  height: 25px;
`;

const Row = styled.div`
  text-align: center;
  font-size: 15px;
  line-height: 1.1em;
  font-family: Ruda Bold;
  text-transform: uppercase;
  color: ${$.color.black2};
  background-color: transparent;
  transition: all 0.3s ${$.easingFn.standard};
  user-select: none;

  &:hover {
    cursor: pointer;
    background-color: ${$.color.gray1};
  }
`;

const InnerContainer = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  width: 200px;
  border: 1px solid ${$.color.gray1};
  border-radius: ${$.border().radius1}px;
  box-shadow: ${$.dropShadow.normal};
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  transition: all 0.3s ${$.easingFn.standard};
  background-color: ${$.color.white};
  transform: translateY(-1.5em);

  > * {
    padding: ${$.layout().padding2}px;
    width: calc(100% - ${$.layout().padding2 * 2}px);
  }

  ${Row} {
    border-bottom: 1px solid ${$.color.gray1};

    &:nth-child(2) {
      border-top: 1px solid ${$.color.gray1};
    }
  }

  &.clicked {
    pointer-events: initial;
    transform: translateY(0);
    opacity: 1;
    z-index: 100;
  }
`;

const StyledPersonIcon = styled(PersonIcon)`
  width: 30px;
  height: 100%;
  transition: all 0.3s ease;
  &:hover {
    cursor: pointer;
    fill: ${$.color.blue4};
  }
`;

const ProfileButton = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > svg {
    fill: ${$.color.blue5};
  }

  &.clicked {
    > svg {
      fill: ${$.color.blue4};
    }
  }
`;

const Name = styled.div`
  font-size: 18px;
  color: ${$.color.blue4};
  text-align: center;
  ${$.ellipsis}
`;

const dropdownList = Utils.addKeys([
  {
    link: '/instructor/class/',
    text: 'Go to class',
  },
  {
    link: '/instructor/create/',
    text: 'Create class',
  },
  {
    link: '/instructor/new/',
    text: 'Add student to registry',
  },
  {
    link: '/instructor/student-vms/',
    text: 'Active Student VMs',
  },
  {
    link: '/instructor/vm/',
    text: 'Manual VM',
  },
]);

/**
 * Dropdown for the nav bar when you click on the person icon.
 */
const Dropdown = () => {
  const [clicked, setClicked] = useState(false);
  const [counter, setCounter] = useState(0);
  const dropdownRef = useRef(null);
  const state = useSelector(({ user }) => {
    return { user };
  });

  /**
   * Hide the dropdown when user clicks elsewhere.
   */
  Utils.useOutsideClick([dropdownRef], () => {
    setClicked(prev => {
      if (prev) {
        setCounter(prevCounter => {
          return prevCounter + 1;
        });

        return false;
      }

      return prev;
    });
  });

  return (
    <Container ref={dropdownRef}>
      <ReactTooltip delayShow={500} />
      <ProfileButton
        data-tip="Menu"
        className={clicked ? 'clicked' : ''}
        onClick={() => {
          setCounter(prev => {
            return prev + 1;
          });
          setClicked(prev => !prev);
        }}
      >
        <StyledPersonIcon />
        <StyledRotatingDropArrow counter={counter} />
      </ProfileButton>
      <InnerContainer className={clicked ? 'clicked' : ''}>
        <Name>{`Hello, ${state.user.name}`}</Name>
        {state.user.role === TYPES.INSTRUCTOR_ROLE &&
          dropdownList.map(({ key, text, link }) => {
            return (
              <Row
                key={key}
                onClick={() => {
                  navigate(link);
                  setClicked(false);
                }}
              >
                {text}
              </Row>
            );
          })}
        <Row
          onClick={() => {
            Auth.signOut().catch(err => console.log(err));
          }}
        >
          Logout
        </Row>
      </InnerContainer>
    </Container>
  );
};

export default Dropdown;
