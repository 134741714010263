import { useEffect } from 'react';
import shortid from 'shortid';
import { navigate } from 'gatsby';

import TYPES from '../actions/types';
import useActions from './useActions';

/**
 * Informs if a click outside the given ref is done.
 * @param {array} refArray - Array of component refs to avoid
 * @param {func} callback - A callback function to run if user clicked outside the array of
 *  components
 */
const useOutsideClick = (refArray, callback) => {
  const handleOutsideClick = event => {
    let outsideClick = false;

    if (!(Array.isArray(refArray) && refArray.length > 0)) {
      console.log('Please pass in an array of refs.');
    }

    refArray.forEach(ref => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (refArray.length > 1) {
          refArray
            .filter(refPrime => {
              if (ref === refPrime) {
                return false;
              }
              return true;
            })
            .forEach(refPrime => {
              if (!refPrime.current.contains(event.target)) {
                outsideClick = true;
              }
            });
        } else {
          outsideClick = true;
        }
      }
    });

    if (outsideClick) {
      callback(event);
    }
  };

  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      if (typeof document !== 'undefined') {
        document.removeEventListener('mousedown', handleOutsideClick);
      }
    };
  }, []);
};

/**
 * Takes in 1 array of objects, and add keys to them.
 * @param {array} items - 1 array of objects.
 * @returns {array}
 */
const addKeys = items =>
  items.map(item => ({ ...item, key: shortid.generate() }));

/**
 * For VNC use. Splits the given IP into the host and port.
 * @param {string} ipStr - IP address
 */
const getHostAndPort = ipStr => {
  const split = ipStr.split(':');

  return { host: split[0], port: Number(split[1]) };
};

/**
 * Acts as a gateway to bounce illegal trespasser off. Instructor can only view
 * instructor pages, and student can only view the index page.
 * @param {string} role - Returns the instructor or student role.
 */
const redirect = role => {
  const path = typeof window !== 'undefined' ? window.location.pathname : '';

  switch (role) {
    case TYPES.INSTRUCTOR_ROLE:
      if (path && path.indexOf('/instructor') === -1) {
        navigate('/instructor/');
      }
      break;
    case TYPES.STUDENT_ROLE:
      if (path && path !== '/') {
        navigate('/student/');
      }
      break;
    default:
      break;
  }
};

export default {
  redirect,
  getHostAndPort,
  useOutsideClick,
  addKeys,
  useActions,
};
