const config = {
  Auth: {
    identityPoolId: 'ap-southeast-1:82959ced-baf2-48ad-8d70-b43aad9cc361',
    region: 'ap-southeast-1',
    userPoolId: 'ap-southeast-1_8VCQAzJEr',
    userPoolWebClientId: '3q4dirjk7r58uiaopv7oeji0im',
  },
};

export default config;
