import React from 'react';
import TYPES from '../actions/types';

const initialState = {
  show: false,
  title: '',
  // eslint-disable-next-line
  components: <></>,
  buttons: [],
};

export default (state = initialState, { payload = {}, type }) => {
  let newState = { ...state };

  switch (type) {
    case TYPES.OPEN_MODAL:
      newState.show = true;

      if (typeof payload.title !== 'undefined') {
        newState.title = payload.title;
      }

      if (typeof payload.components !== 'undefined') {
        newState.components = payload.components;
      }

      if (typeof payload.buttons !== 'undefined') {
        newState.buttons = payload.buttons;
      }

      return newState;
    case TYPES.CLOSE_MODAL:
      newState = {
        show: false,
        title: '',
        components: <></>,
        buttons: [],
      };
      return newState;
    default:
      return state;
  }
};
