import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../styles/global';

const Container = styled.button`
  border-radius: ${$.border().radius1}px;
  background-color: ${$.color.blue3};
  color: ${$.color.white};
  border: 2px solid transparent;
  min-width: 80px;
  padding: 8px 10px;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 1px;
  font-family: Ruda Bold;
  transition: all 0.3s ${$.easingFn.standard};
  outline: none;

  &:hover {
    cursor: pointer;
    border: 2px solid ${$.color.blue3};
    color: ${$.color.blue3};
    background-color: ${$.color.white};
  }
`;

const Button = ({ children, className, ...rest }) => {
  return (
    <Container className={className} {...rest}>
      {children}
    </Container>
  );
};

Button.defaultProps = {
  children: '',
  className: '',
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.element,
  ]),
  className: PropTypes.string,
};

export default Button;
