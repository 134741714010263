import TYPES from '../actions/types';

const initialState = {
  instructor: {},
  twilioRoomName: '',
  studentVM: '',
  wsInstance: undefined,
  activeView: '',
};

export default (state = initialState, { payload, type }) => {
  const newState = { ...state };

  switch (type) {
    /**
     * Sets up student session. Stores instructor VM and student VM.
     * These values mentioned will not change. Consider them a source of truth.
     * Also sets up the initial active view.
     */
    case TYPES.SET_STUDENT_SESSION:
      newState.instructor = payload.instructor;
      newState.twilioRoomName = payload.twilioRoomName;
      newState.studentVM = payload.studentVM;

      newState.activeView = payload.activeView || payload.instructor.ipAddress;

      newState.wsInstance = payload.wsInstance;
      return newState;

    /**
     * We need to clear up the session once the student logged out or class has stopped.
     */
    case TYPES.CLEAR_STUDENT_SESSION:
      return {
        instructor: {},
        twilioRoomName: '',
        studentVM: '',
        wsInstance: undefined,
        activeView: { type: '', value: '' },
      };

    /**
     * Swaps VM IP address.
     */
    case TYPES.SET_INSTRUCTOR_VIEW:
      newState.activeView = payload.value;
      return newState;

    default:
      return state;
  }
};
